<template>
  <Content>
    <template #contentBody>
      <a-row>
        <a-col :span="24">
          <a-steps :current="0"
                   style="max-width:1800px;"
                   size="small">
            <a-step :title="$t('warehouse.create_allot_to_storage_of_plan')" />
            <a-step :title="$t('warehouse.select_goods_and_packaging')" />
            <a-step :title="$t('warehouse.set_box_number')" />
            <a-step :title="$t('common.completed')" />
          </a-steps>
        </a-col>
      </a-row>
      <a-form ref="refForm"
              :model="data"
              :rules="rules"
              class="mt-5">
        <a-form-item name="selectedWarehouse"
                     :label="$t('warehouse.select_need_operate_consignment')">
          <a-select style="width: 250px"
                    v-model:value="data.selectedWarehouse"
                    :placeholder="$t('warehouse.warehouse_code')"
                    allow-clear
                    :showSearch="true"
                    optionFilterProp="search-key"
                    :loading="data.loading">
            <a-select-option v-for="(item,key) in data.warehouses"
                             :key="key"
                             :search-key="item.warehouseNo + item.warehouseName"
                             :value="item.warehouseId">
              {{item.warehouseNo}}({{item.warehouseName}})
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item class="ml-5">
          <a-button type="primary"
                    @click="handleCreate">{{$t('common.next_step')}}</a-button>
        </a-form-item>
      </a-form>
    </template>
  </Content>
</template>

<script>
import { reactive, onMounted, ref } from "vue";
import {
  Row, Col, Select, Button, Form, Steps
} from "ant-design-vue";
import Content from "../../components/Content.vue";
import { getUserWarehouseWithStock } from "../../../api/modules/common/index";
import { useRouter } from 'vue-router';
import { useI18n } from "vue-i18n/index";
import { useTab } from "../../../hooks/tabs/index";

export default ({
  name: "consignment_allot_create",
  components: {
    Content,
    ARow: Row,
    ACol: Col,
    ASelect: Select,
    AButton: Button,
    ASelectOption: Select.Option,
    AFormItem: Form.Item,
    AForm: Form,
    ASteps: Steps,
    AStep: Steps.Step,
  },
  setup () {
    const vueI18n = useI18n({ useScope: "global" });
    const router = useRouter();
    const { delVisitedRoute } = useTab();
    const refForm = ref();
    const data = reactive({
      warehouses: [],
      loading: false,
      selectedWarehouse: null
    });

    const handleCreate = async () => {
      refForm.value.validate().then((res) => {
        let warehouse = data.warehouses.find(x => x.warehouseId == data.selectedWarehouse);
        if (warehouse) {
          delVisitedRoute(router.currentRoute.value);
          router.push(
            {
              name: "consignment_allot_set_box",
              params: {
                warehouseId: warehouse.warehouseId,
                warehouseNo: warehouse.warehouseNo,
              }
            });
        }
      }).catch(() => { })
    }

    const getWarehouses = () => {
      data.loading = true;
      getUserWarehouseWithStock().then((res) => {
        if (res.result) {
          data.warehouses = res.result;
        }
        data.loading = false;
      }).catch(() => {
        data.loading = false;
      })
    }

    const rules = {
      selectedWarehouse: [
        {
          required: true,
          message: () => {
            return vueI18n.t("common.p0_is_required", [vueI18n.t("warehouse.select_need_operate_consignment")])
          },
          trigger: 'blur',
        },
      ]
    }

    onMounted(async () => {
      getWarehouses();
    })

    return {
      data,
      rules,
      refForm,
      handleCreate

    };
  }
})
</script>

<style lang="less" scoped>
</style>